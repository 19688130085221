import React from 'react';
import styled from '@emotion/styled';
import { Tabs, Tab, Paper } from '@mui/material';
import { Link } from 'gatsby';

const STabs = styled(Tabs)`
  && {
    margin-top: var(--small);
    margin-bottom: var(--small);
    display: flex;
    justify-content: center;
    padding: var(--small);

    & a {
      color: black;
    }
    & a.active {
      color: var(--color-primary);
      border-bottom: 2px solid var(--color-primary);
    }
    & a:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const CalculatorTabs = () => (
  <Paper variant="outlined">
    <STabs
      scrollButtons="on"
      indicatorColor="secondary"
      textColor="secondary"
      centered
    >
      <Tab
        label="BMI"
        component={Link}
        to="/bmi-kalkulator/"
        activeClassName="active"
      />
      <Tab
        label="BMR"
        component={Link}
        to="/bmr-kalkulator/"
        activeClassName="active"
      />
      <Tab
        label="Kalorikalkulator"
        component={Link}
        to="/kalorikalkulator-kaloribehov/"
        activeClassName="active"
      />
    </STabs>
  </Paper>
);
